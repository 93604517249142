<template>
  <div class="login b-card pa-4 pt-7 pb-9">
    <div
      class="access"
      v-if="access"
    > {{ $t('login.accessKey') }}
    </div>
    <div
      class="no-access"
      v-else
    >
      <v-icon color="red">mdi-exclamation-thick</v-icon>
      {{ $t('login.noAccessKey') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'KeyVerification',
  data () {
    return {
      access: false
    }
  }
}
</script>

<style
  scoped
  lang="scss"
>
  .no-access, .access {
    font-size: 16px;
    text-align: center;
  }
  .access {
    color: $light-breeze;
  }
  .no-access {
    color: $light-breeze;
  }
</style>